import dsdata from './discogs-data'

export type Config = {
    BUILD_ID: string
    NODE_ENV?: string
    SEARCH_URL: string
    DATADOG_APP_ID?: string
    DATADOG_CLIENT_TOKEN?: string
    DATADOG_SAMPLE_RATE: number
    GTM_CLIENT_ID?: string
    GTM_PREVIEW_ID?: string
    GTM_AUTH_TOKEN?: string
    API_URL?: string
    REDIRECT_DEFAULT_LOCALE?: boolean
    AD_SCRIPT: string
    DISABLE_ADS: boolean
    ASSET_HOST: string
    YOUTUBE_API_KEY: string
    DISCOGS_HOST: string
}

export type ServerConfig = Config & {
    PORT: number
    RELEASE_API_HOSTS: string[]
    RELEASE_API_TOKEN?: string
    DD_ENV?: string
    DD_TRACE_AGENT_HOSTNAME?: string
    DD_TRACE_AGENT_PORT?: string
    IMG_HOST: string
    API_TIMEOUT: number
    REQUEST_TIMEOUT: number
    LOG_LEVEL: string
    ALLOW_IMPERSONATION: boolean
    REQUIRE_OPT_IN: boolean

    ESI_INLINE: boolean
}

export const config = process.browser && !process.isDevWatching ? browser() : server()
export const serverConfig = secret()

/**
 * Read the config in the browser context.
 * Expects the dsdata script tag to be present.
 */
function browser(): Config {
    return dsdata.config
}

/**
 * Read the config in the server context.
 * This reads config from process.env and the .env file.
 */
function server(): Config {
    return {
        // @ts-expect-error: __BUILD_ID__ is set in ../config/rollup.server.js
        BUILD_ID: global.__BUILD_ID__ ?? '<none>',
        NODE_ENV: env('NODE_ENV', 'development'),
        SEARCH_URL: env('SEARCH_URL', ''),
        DATADOG_APP_ID: env('DATADOG_APP_ID', null),
        DATADOG_CLIENT_TOKEN: env('DATADOG_CLIENT_TOKEN', null),
        DATADOG_SAMPLE_RATE: parseFloat(env('DATADOG_SAMPLE_RATE', '0')),
        GTM_CLIENT_ID: env('GTM_CLIENT_ID', null),
        GTM_PREVIEW_ID: env('GTM_PREVIEW_ID', null),
        GTM_AUTH_TOKEN: env('GTM_AUTH_TOKEN', null),
        API_URL: env('API_URL', null),
        REDIRECT_DEFAULT_LOCALE: flag('REDIRECT_DEFAULT_LOCALE'),
        AD_SCRIPT: env('AD_SCRIPT', 'https://lngtd.com/discogs_a.js'),
        DISABLE_ADS: flag('DISABLE_ADS'),
        ASSET_HOST: env('ASSET_HOST', '/_assets/'),
        YOUTUBE_API_KEY: env('YOUTUBE_API_KEY', ''),
        DISCOGS_HOST: env('DISCOGS_HOST', 'https://www.discogs.com'),
    }
}

function secret(): ServerConfig {
    if (process.browser) {
        // @ts-expect-error
        return null
    }

    return {
        ...server(),
        PORT: parseInt(env('PORT', '3000'), 10),
        RELEASE_API_HOSTS: list('RELEASE_API_HOSTS'),
        RELEASE_API_TOKEN: env('RELEASE_API_TOKEN', null),
        DD_ENV: env('DD_ENV', null),
        DD_TRACE_AGENT_HOSTNAME: env('DD_TRACE_AGENT_HOSTNAME', null),
        DD_TRACE_AGENT_PORT: env('DD_TRACE_AGENT_PORT', null),
        IMG_HOST: env('IMG_HOST', 'https://img.discogs.com'),
        API_TIMEOUT: parseInt(env('API_TIMEOUT', '10000'), 10),
        REQUEST_TIMEOUT: parseInt(env('REQUEST_TIMEOUT', '30000'), 10),
        LOG_LEVEL: env('LOG_LEVEL', 'warn'),
        ALLOW_IMPERSONATION: flag('ALLOW_IMPERSONATION'),
        REQUIRE_OPT_IN: flag('REQUIRE_OPT_IN'),
        ESI_INLINE: flag('ESI_INLINE'),
    }
}

function env(name: string): string
function env(name: string, fallback: string): string
function env(name: string, fallback: null): string | undefined
function env(name: string, fallback?: string | null): string | undefined {
    const value = process.env[name]
    if (!value) {
        if (fallback !== undefined) {
            return fallback ?? undefined
        }
        throw new Error(`Missing environment variable "${name}"`)
    }
    return value
}

function flag(name: string): boolean {
    const value = env(name, null)
    return value === '1' || value === 'true'
}

function list(name: string): string[] {
    const value = env(name)
    return value.split(',').map((s: string): string => s.trim())
}
