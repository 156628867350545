import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'

const explore = {
    title: (i18n: I18n): string => t(i18n)`Explore`,
    toggle: (i18n: I18n): string => t(i18n)`Toggle Explore Menu`,
    href: '/search',
    groups: [
        {
            title: (i18n: I18n): string => t(i18n)`Discover`,
            items: [
                {
                    title: (i18n: I18n): string => t(i18n)`Explore All`,
                    href: '/search?ev=em_rs',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`List Explorer`,
                    href: '/lists?ev=em_lr',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Advanced Search`,
                    href: '/search/advanced?ev=em_as',
                },
            ],
        },
        {
            title: (i18n: I18n): string => t(i18n)`Contribute`,
            items: [
                {
                    title: (i18n: I18n): string => t(i18n)`Submission Guidelines`,
                    href: 'https://support.discogs.com/hc/en-us/articles/360004016474-Overview-of-Submission-Guidelines-for-Releases?utm_campaign=navigation_menu&utm_medium=referral&utm_source=discogs',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Submit a Release`,
                    href: '/release/add?ev=em_sr',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`How to Make a List`,
                    href: 'https://support.discogs.com/hc/en-us/articles/360001567973?utm_campaign=navigation_menu&utm_medium=referral&utm_source=discogs',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Monthly Leaderboards`,
                    href: '/leaderboard',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Help Translate Discogs`,
                    href: 'https://www.discogs.com/group/thread/963554',
                },
            ],
        },
        {
            title: (i18n: I18n): string => t(i18n)`Genres`,
            items: [
                {
                    title: (i18n: I18n): string => t(i18n)`Rock`,
                    href: '/genre/rock?ev=em_rp',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Electronic`,
                    href: '/genre/electronic?ev=em_ep',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Hip Hop`,
                    href: '/genre/hip+hop?ev=em_pp',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Folk, World, & Country`,
                    href: '/genre/folk%2C+World%2C+%26+Country?ev=em_fwcp',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Jazz`,
                    href: '/genre/jazz?ev=em_jp',
                },
            ],
        },
        {
            title: (i18n: I18n): string => t(i18n)`Styles`,
            items: [
                {
                    title: (i18n: I18n): string => t(i18n)`House`,
                    href: '/style/house?ev=em_hp',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Punk`,
                    href: '/style/punk?ev=em_pup',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Soul`,
                    href: '/style/soul?ev=em_vp',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Experimental`,
                    href: '/style/experimental?ev=em_vp',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Prog Rock`,
                    href: '/style/prog%20rock?ev=em_vp',
                },
            ],
        },
    ],
}

const marketplace = {
    title: (i18n: I18n): string => t(i18n)`Marketplace`,
    toggle: (i18n: I18n): string => t(i18n)`Toggle Marketplace Menu`,
    href: '/sell/list',
    groups: [
        {
            title: (i18n: I18n): string => t(i18n)`Format`,
            items: [
                {
                    title: (i18n: I18n): string => t(i18n)`Vinyl`,
                    href: '/sell/list?format=Vinyl',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`CD`,
                    href: '/sell/list?format=CD',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Cassette`,
                    href: '/sell/list?format=Cassette',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`DVD`,
                    href: '/sell/list?format=DVD',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Box Set`,
                    href: '/sell/list?format=Box+Set',
                },
            ],
        },
        {
            title: (i18n: I18n): string => t(i18n)`Genre`,
            items: [
                {
                    title: (i18n: I18n): string => t(i18n)`Rock`,
                    href: '/sell/list?genre=Rock',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Electronic`,
                    href: '/sell/list?genre=Electronic',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Pop`,
                    href: '/sell/list?genre=Pop',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Funk / Soul`,
                    href: '/sell/list?genre=Funk+%2F+Soul',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Jazz`,
                    href: '/sell/list?genre=Jazz',
                },
            ],
        },
        {
            title: (i18n: I18n): string => t(i18n)`Style`,
            items: [
                {
                    title: (i18n: I18n): string => t(i18n)`Pop Rock`,
                    href: '/sell/list?style=Pop+Rock',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`House`,
                    href: '/sell/list?style=House',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Disco`,
                    href: '/sell/list?style=Disco',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Synth-Pop`,
                    href: '/sell/list?style=Synth-Pop',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Soul`,
                    href: '/sell/list?style=Soul',
                },
            ],
        },
        {
            title: {
                title: (i18n: I18n): string => t(i18n)`Buy Music`,
                href: '/sell/list',
            },
            items: [
                {
                    title: (i18n: I18n): string => t(i18n)`All Items`,
                    href: '/sell/list',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Items I Want`,
                    href: '/sell/mywants',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Purchases`,
                    href: '/sell/purchases',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Cart`,
                    href: '/sell/cart',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Record Stores`,
                    href: '/record-stores/',
                },
            ],
        },
        {
            title: (i18n: I18n): string => t(i18n)`Sell Music`,
            items: [
                {
                    title: (i18n: I18n): string => t(i18n)`Start Selling`,
                    href: '/selling',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`List Items For Sale`,
                    href: '/sell',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Orders`,
                    href: '/sell/orders',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Inventory`,
                    href: '/sell/manage',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Billing`,
                    href: '/sell/billing',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`My Store`,
                    href: '/seller/my/profile',
                },
            ],
        },
    ],
}

const community = {
    title: (i18n: I18n): string => t(i18n)`Community`,
    toggle: (i18n: I18n): string => t(i18n)`Toggle Community Menu`,
    href: '/group',
    align: true,
    groups: [
        {
            items: [
                {
                    title: (i18n: I18n): string => 'Discogs Digs',
                    href: 'https://www.discogs.com/digs',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Groups`,
                    href: '/group',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Forum`,
                    href: '/forum',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Friends`,
                    href: '/users/friends',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Community Guidelines`,
                    href: '/help/doc/forums',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Database Guidelines`,
                    href: 'https://support.discogs.com/hc/en-us/articles/360004016474-Overview-of-Submission-Guidelines-for-Releases',
                },
                {
                    title: (i18n: I18n): string => t(i18n)`Record Stores`,
                    href: 'https://www.discogs.com/record-stores/?utm_source=discogs-top-nav&utm_medium=referral',
                },
            ],
        },
    ],
}

export const categories = [explore, marketplace, community]
